import React from 'react';
import PropTypes from 'prop-types';

function FullscreenLoader({ title }) {
  return (
    <div className='fullscreen-wrapper'>
      {
        title && (
          <h1 className='loading-text' >{title}</h1>
        )
      }
    </div>
  );
}

FullscreenLoader.propTypes = {
  title: PropTypes.string,
}

FullscreenLoader.defaultProps = {
  title: '',
}

export default FullscreenLoader;