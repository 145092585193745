import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

function Loader({ visible }) {
  return (
    <div className={classnames('loader', {
      'hidden': !visible
    })}>
      <div className="lds-ripple"><div></div><div></div></div>
      <p>Loading image</p>
    </div>
  );
}

Loader.propTypes = {
  visible: PropTypes.bool
};

Loader.defaultProps = {
  visible: true
}

export default Loader;
