function modelResultsToLabeledBoundingBoxes(modelResults) {
  const labeledBoundigBoxes = modelResults.reduce(
    (prev, curr) => [
      ...prev,
      ...curr.results.map(r => ({
        id: r.id,
        model: curr.modelFamily,
        label: r.label,
        score: r.score,
        box: r.box,
        text: JSON.parse(r.text)
      }))
    ],
    []
  );

  return labeledBoundigBoxes;
}

export default modelResultsToLabeledBoundingBoxes;
