import React from 'react';
import PropTypes from 'prop-types';

function DimmerArea({ onAreaClick, children }) {
  return (
    <div className="dimmer fade-in" onClick={onAreaClick}>
      {children}
    </div>
  );
}

DimmerArea.propTypes = {
  onAreaClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default DimmerArea;
