import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Badge, Card } from 'reactstrap';
import { useParams } from 'react-router';
import useFetch from 'hooks/useFetch';
import FullscreenLoader from 'components/FullscreenLoader';
import FullscreenError from 'components/FullscreenError';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import modelResultsToHighlightedAreas from 'utils/modelResultsToHighlightedAreas';
import ImageTexts from 'components/ImageTexts';
import Metadata from 'components/Metadata';

function PublicPhotoDetail() {
  const { id } = useParams();
  const { loading, error, data } = useFetch(`https://${process.env.REACT_APP_API_URL}/a/${id}`)
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const handleShowAllToggle = () => {
    const visibleAreas = highlightedAreas.filter(area => area.enabled === true);
    if (visibleAreas.length === highlightedAreas.length) {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: false
      }));
      setHighlightedAreas(allAreas);
    } else {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: true
      }));
      setHighlightedAreas(allAreas);
    }
  };

  const handleHighlightedAreaToggle = areaId => {
    const updatedAreas = highlightedAreas.map(area => ({
      ...area,
      enabled: area.id === areaId ? !area.enabled : area.enabled
    }));
    setHighlightedAreas(updatedAreas);
  };

  useEffect(() => {
    if (!data) return
    const newHighlightedAreas = modelResultsToHighlightedAreas(data.modelResults.v1) || [];
    setHighlightedAreas(newHighlightedAreas);
  }, [data]);

  if (loading) return <FullscreenLoader title="Loading data..." />
  if (error) return <FullscreenError title="Failed to load media" subtitle="Please try to change media hash in URL or contact our support." />

  const visibleAreas = highlightedAreas.filter(area => area.enabled === true);
  const imageTexts = highlightedAreas.map(area => ({
    id: `${area.name}:${area.percentage}`,
    text: area.text[0]?.text,
    label: area.text[0]?.label,
  }))

  const { image: { url, height, width }, metadata } = data;

  return (
    <div className="bg-secondary h-100vh">
      <Row className="h-100 d-flex pl-4">
        <Col xs="8" className="d-flex flex-column justify-content-center">
          <Card className="shadow p-2 h-95vh">
            <ImageWithHighlightedArea
              imageUrl={url}
              originalImageWidth={width}
              originalImageHeight={height}
              highlightedAreas={visibleAreas}
            />
            <div className='floating-filter-wrapper'>
              {
                showFilter && (
                  <Card className="shadow p-4 mb-4 rounded">
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between">
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="customCheck5"
                              type="checkbox"
                              checked={
                                visibleAreas.length === highlightedAreas.length &&
                                highlightedAreas.length > 0
                              }
                              disabled={highlightedAreas.length < 1}
                              onClick={() => handleShowAllToggle()}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck5"
                            >
                              show bounding boxes
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {highlightedAreas.map(area => (
                          <Button
                            type="button"
                            className="mb-2 ml-0"
                            onClick={() => handleHighlightedAreaToggle(area.id)}
                            style={{
                              backgroundColor: area.enabled && area.strokeColor
                            }}
                          >
                            {area.name}
                          </Button>
                        ))}
                      </Col>
                    </Row>
                  </Card>
                )
              }
              <Button className="btn-icon p-4 rounded-pill" color="warning" type="button" size="lg" onClick={() => setShowFilter(prev => !prev)}>
                <span className="btn-inner--icon">
                  {
                    !showFilter ? <i className="fa fa-filter filter-icon" aria-hidden="true"></i> : <i className="fa fa-times filter-icon" aria-hidden="true" ></i>
                  }
                </span>
              </Button>
            </div>
          </Card>
        </Col>
        <Col xs="4" className="texts-wrapper">
          <Metadata metadata={metadata} />
          <ImageTexts texts={imageTexts} />
        </Col>
      </Row>
    </div>
  );
}

export default PublicPhotoDetail;