import { useReducer, useEffect } from 'react';

const initialState = {
  loading: true,
  error: undefined,
  data: undefined
};

function reducer(state, action) {
  switch (action.type) {
    case 'SUCCESS':
      return {
        loading: false,
        error: undefined,
        data: action.data
      };
    case 'FAILURE':
      return {
        loading: false,
        error: action.error,
        data: undefined
      };
    default:
      throw new Error(`unrecognized action type '${action.type}'`);
  }
}

function fetchPromise(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  });
}

const useFetch = (url) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = async () => {
    try {
      const data = await fetchPromise(url)
      dispatch({ type: 'SUCCESS', data })
    } catch (error) {
      dispatch({ type: 'FAILURE', error })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    ...state
  }
}

export default useFetch