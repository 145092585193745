import React from 'react';
import PropTypes from 'prop-types';

function CroppedImage({
  imageUrl,
  croppedArea,
  originalImageWidth,
  originalImageHeight,
  alt,
  center,
  shadow,
  maxWidth,
  maxHeight
}) {
  const { x1, y1, x2, y2 } = croppedArea;
  const widthPercentage = x2 - x1;
  const heightPercentage = y2 - y1;
  const topMarginPercentage = y1;
  const leftMarginPercentage = x1;

  const originalImageBBWidth = originalImageWidth * widthPercentage;
  const originalImageBBHeight = originalImageHeight * heightPercentage;
  const bbRatio = originalImageBBWidth / originalImageBBHeight;

  let wrapperWidth = maxWidth;
  let wrapperHeight = wrapperWidth / bbRatio;
  if (wrapperHeight > maxHeight) {
    const changeRatio = maxHeight / wrapperHeight;
    wrapperWidth *= changeRatio;
    wrapperHeight = maxHeight;
  }

  const resizeRatio = wrapperWidth / (originalImageWidth * widthPercentage);
  const imageWidth = originalImageWidth * resizeRatio;
  const imageHeight = originalImageHeight * resizeRatio;

  const topMargin = imageHeight * topMarginPercentage;
  const leftMargin = imageWidth * leftMarginPercentage;

  return (
    <div
      style={{
        width: wrapperWidth,
        height: wrapperHeight,
        overflow: 'hidden',
        marginLeft: center ? 'auto' : null,
        marginRight: center ? 'auto' : null,
        boxShadow: shadow
          ? 'box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175)'
          : null,
        border: '3px solid #35A8FF'
      }}
    >
      <img
        src={imageUrl}
        alt={alt}
        style={{
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          margin: `-${topMargin}px 0 0 -${leftMargin}px`
        }}
      />
    </div>
  );
}

CroppedImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  croppedArea: PropTypes.shape({
    x1: PropTypes.number.isRequired,
    y1: PropTypes.number.isRequired,
    x2: PropTypes.number.isRequired,
    y2: PropTypes.number.isRequired
  }).isRequired,
  originalImageWidth: PropTypes.number.isRequired,
  originalImageHeight: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  alt: PropTypes.string,
  center: PropTypes.bool,
  shadow: PropTypes.bool
};

CroppedImage.defaultProps = {
  alt: '#',
  center: false,
  shadow: false
};

export default CroppedImage;
