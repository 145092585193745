import stringToASCIISum from 'utils/stringToASCIISum';

const colors = [
  `255,252,0`,
  `255,8,232`,
  `65,253,254`,
  `0,255,0`,
  `102,255,0`,
  `255,0,0`,
  `255,192,0`,
  `102,255,0`,
  `255,255,0`
];


function stringToColor(s) {
  const value = stringToASCIISum(s);
  const m = value % colors.length;
  return colors[m];
}

export default stringToColor;
