import React from 'react';
import PropTypes from 'prop-types';

function FullscreenError({ title, subtitle }) {
  return (
    <div className='fullscreen-wrapper'>
      <h1 className='text-warning'>{title}</h1>
      {
        subtitle && (
          <h4 className='text-muted'>{subtitle}</h4>
        )
      }
    </div>
  );
}

FullscreenError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

FullscreenError.defaultProps = {
  title: 'Something went wrong',
  subtitle: ''
}

export default FullscreenError;