import React from 'react';
import PropTypes from 'prop-types';

function TextComponent({ label, text }) {
  switch (label) {
    case "title":
      return <h1>{text}</h1>
    case "subtitle":
      return <h2>{text}</h2>
    case "text":
      return <p>{text}</p>
    case "appendix":
      return <p><small className="text-uppercase text-muted font-weight-bold">{text}</small></p>
    case "author":
    case "about_author":
      return <p><cite title={text}>{text}</cite></p>
    case "photo_author":
    case "photo_caption":
      return <p><small className="text-uppercase text-muted font-weight-bold">{text}</small></p>
    case "advertisement":
      return <p><small className="text-uppercase text-muted font-weight-bold">{text}</small></p>
    case "image":
    case "other_graphics":
      return <p><small className="text-uppercase text-muted font-weight-bold">{text}</small></p>
    case "interview":
      return <p className="lead text-italic">{text}</p>
    default:
      return <p className="text-muted mb-0">{text}</p>
  }
}

TextComponent.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

function ImageTexts({ texts }) {
  return (
    <div>
      {
        texts.map(({ id, label, text }) => <TextComponent label={label} text={text} key={id} />)
      }
    </div>
  );
}

ImageTexts.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }))
}


export default ImageTexts;