import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

function metadataIconAndColor(key) {
  switch (key) {
    case 'issue':
      return ['fa fa-building', 'default']
    case 'issuedAt':
      return ['fa fa-calendar', 'primary']
    case 'page':
      return ['fa fa-file-o', 'success']
    default:
      return null
  }
}

function MetadataPill({ k, value }) {
  const [icon, color] = metadataIconAndColor(k);

  if (!icon) {
    return (
      <Badge className="text-uppercase" color="secondary" pill>
        {value}
      </Badge>
    )
  }

  return (<Badge className="text-uppercase" color={color} pill>
    <i className={`${icon} mr-2`} aria-hidden="true"></i>{value}
  </Badge>)
}

function Metadata({ metadata }) {
  return (
    <div className="metadata">
      {
        metadata.map(({ key, value }) => <MetadataPill key={key} k={key} value={value} />)
      }
    </div>
  );
}

Metadata.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))
}

export default Metadata;