import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/css/argon-design-system-react.min.css";
import "assets/css/searpent.css";

import PublicPhotoDetail from "views/PublicPhotoDetail";
import FullscreenError from "components/FullscreenError";


const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/not-found" exact component={() => <FullscreenError title="Media not found" subtitle="Media hash in URL is missing. Please provide one after '/' or contact our support." />} />
                <Route path="/:id" exact component={PublicPhotoDetail} />
                <Redirect to="/not-found" />
            </Switch>
        </BrowserRouter>
    )
}

export default App