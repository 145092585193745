/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ImageMapper from 'components/ImageMapper';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import calculateAbsoluteBoundingBoxCoordinates from 'utils/calculateAbsoluteBoundingBoxCoordinates';
import { Row, Col } from 'reactstrap';
import relativeArea from 'utils/relativeArea';
import DimmerArea from './DimmerArea';
import CroppedImage from './CroppedImage';
import Loader from 'components/Loader';

function isValidCoords(coords) {
  if (!coords || coords.length !== 4) {
    return false;
  }

  for (const coord of coords) {
    if (Number.isNaN(coord)) {
      return false;
    }
  }

  return true;
}

// ImageWithHighlightedArea is wrapper for ImageMapper
//
// Component ImageWrapper needs prop `width` as absolute value, to get this value and stay responsive,
// first we need to render `ImageWithHighlightedArea` component to full width of its parent element. Then
// at `componentDidMount` we can access its width and pass it to `ImageMapper`.
class ImageWithHighlightedArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dimmerOpen: false,
      focusedAreaIndex: undefined,
      hoveredArea: null,
      allLoaded: false
    };
  }

  componentDidMount() {
    // after rendered, set wrapper dimensions
    this.setState({
      wrapperDimensions: {
        width: this.wrapperElement.offsetWidth,
        height: this.wrapperElement.offsetHeight
      }
    });
  }

  toggleDimmer = e => {
    if (!this.props.clickable) {
      return;
    }
    this.setState(state => {
      return {
        ...state,
        dimmerOpen: !state.dimmerOpen,
        focusedAreaIndex: e.index,
        imageWidth: this.imageElement.img.clientWidth,
        imageHeight: this.imageElement.img.clientHeight
      };
    });
  };

  render() {
    const {
      imageUrl,
      originalImageWidth,
      originalImageHeight,
      highlightedAreas
    } = this.props;
    const { wrapperDimensions, allLoaded } = this.state;
    let imageMapperWidth = wrapperDimensions ? wrapperDimensions.width : 100;

    // check if expected height is not > max allowed, if ss downsize it
    const imageRatio = originalImageWidth / originalImageHeight;
    const imageMapperHeight = imageMapperWidth / imageRatio;
    if (wrapperDimensions?.height && imageMapperHeight > wrapperDimensions.height) {
      imageMapperWidth = imageMapperWidth * (wrapperDimensions.height / imageMapperHeight)
    }

    const { dimmerOpen } = this.state;
    let highlightedAreasWithAbsoluteCoords = highlightedAreas
      ? highlightedAreas.map(highlightedArea => {
        return {
          ...highlightedArea,
          absoluteCoords: highlightedArea.relativeCoords
            ? calculateAbsoluteBoundingBoxCoordinates(
              highlightedArea.relativeCoords,
              originalImageWidth,
              originalImageHeight
            )
            : null,
          relativeArea: relativeArea(highlightedArea.relativeCoords)
        };
      })
      : [];
    highlightedAreasWithAbsoluteCoords = _.orderBy(
      highlightedAreasWithAbsoluteCoords,
      ['relativeArea'],
      ['asc']
    );

    const map = {
      name: v4(),
      areas: highlightedAreasWithAbsoluteCoords.map((area, idx) => ({
        name: area.name,
        index: idx,
        shape: 'rect',
        coords: isValidCoords(area.absoluteCoords) ? area.absoluteCoords : [],
        preFillColor: area.preFillColor,
        fillColor: area.fillColor,
        strokeColor: area.strokeColor,
        lineWidth: area.lineWidth
      }))
    };

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
        className="d-flex align-items-center justify-content-center position-relative"
        ref={el => {
          this.wrapperElement = el;
        }}
        onLoad={() => this.setState({
          allLoaded: true
        })}
      >
        <Loader visible={!allLoaded} />
        {wrapperDimensions && (
          <>
            {
              <ImageMapper
                shouldComponentUpdate={() => {
                  return true;
                }}
                active
                src={imageUrl}
                map={map}
                width={imageMapperWidth}
                imgWidth={originalImageWidth}
                onClick={this.toggleDimmer}
                ref={el => {
                  this.imageElement = el;
                }}
                onMouseEnter={area =>
                  this.setState({
                    hoveredArea: area
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    hoveredArea: null
                  })
                }
                imageVisible={allLoaded}
              />
            }
            {dimmerOpen &&
              highlightedAreasWithAbsoluteCoords[
              this.state.focusedAreaIndex
              ] && (
                <DimmerArea onAreaClick={this.toggleDimmer} noPadding>
                  <Row className="h-100 d-flex align-items-center">
                    <Col className="d-flex justify-content-center zoom-out">
                      <CroppedImage
                        maxWidth={this.state.imageWidth}
                        maxHeight={this.state.imageHeight}
                        imageUrl={imageUrl}
                        originalImageWidth={originalImageWidth}
                        originalImageHeight={originalImageHeight}
                        croppedArea={{
                          x1:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[0],
                          y1:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[1],
                          x2:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[2],
                          y2:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[3]
                        }}
                      />
                    </Col>
                  </Row>
                </DimmerArea>
              )}
          </>
        )}
      </div>
    );
  }
}

ImageWithHighlightedArea.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  originalImageWidth: PropTypes.number.isRequired,
  originalImageHeight: PropTypes.number.isRequired,
  highlightedAreas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shape: PropTypes.oneOf(['rect']).isRequired,
      relativeCoords: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired
  ),
  s3: PropTypes.bool
};

ImageWithHighlightedArea.defaultProps = {
  clickable: true,
  s3: false
};

export default ImageWithHighlightedArea;
