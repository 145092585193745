function stringToASCIISum(s) {
  let sum = 0;
  s.toUpperCase()
    .split('')
    .forEach(alphabet => {
      sum += alphabet.charCodeAt(0);
    });
  return sum;
}

export default stringToASCIISum;
