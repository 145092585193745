import modelResultsToLabeledBoundingBoxes from 'utils/modelResultsToLabeledBoundingBoxes';
import stringToColor from './stringToColor';

function modelResultsToHighlightedAreas(modelResults) {
  const bboxes = modelResultsToLabeledBoundingBoxes(modelResults);
  const highlightedAreas = bboxes
    ? bboxes.map(bbox => ({
      id: `${bbox.model}:${bbox.label}`,
      name: `${bbox.label}`,
      percentage: bbox.score,
      shape: 'rect',
      relativeCoords:
        bbox && bbox.box
          ? [Number(bbox.box.X1), Number(bbox.box.Y1), Number(bbox.box.X2), Number(bbox.box.Y2)]
          : null,
      preFillColor: `rgba(${stringToColor(
        `${bbox.model}:${bbox.label}`
      )}, 0.5)`,
      fillColor: `rgba(${stringToColor(
        `${bbox.model}:${bbox.label}`
      )}, 0.75)`,
      strokeColor: `rgba(${stringToColor(`${bbox.model}:${bbox.label}`)})`,
      lineWidth: 1,
      enabled: true,
      text: bbox.text
    }))
    : [];
  return highlightedAreas;
}

export default modelResultsToHighlightedAreas;