function calculateAbsoluteBoundingBoxCoordinates(
  relativeRectangleCoordinates,
  imageWidth,
  imageHeight
) {
  return [
    imageWidth * relativeRectangleCoordinates[0],
    imageHeight * relativeRectangleCoordinates[1],
    imageWidth * relativeRectangleCoordinates[2],
    imageHeight * relativeRectangleCoordinates[3]
  ];
}

export default calculateAbsoluteBoundingBoxCoordinates;
